.authentication {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .center-frame {
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    min-width: 300px;
    min-height: 300px;
    max-height: 500px;
    background-color: var(--sl-gray08);
    color: var(--sl-gray05);
  }

  .title {
    font-size: 24px;
    color: var(--sl-accent);
    border-bottom: 1px solid var(--sl-accent);
    width: 80%;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 40px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 70%;
    padding-bottom: 30px;

    .label-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    .label {
      margin-left: 10px;
    }

    .password-reveal {
      position: absolute;
      right: 10px;
      top: -2px;
      padding: 2px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      svg {
        width: 20px;
      }
    }

    .password-strength {
      height: 4px;
      border-bottom-left-radius: 5px;
      position: relative;
      top: -4px;
      margin-bottom: -4px;
      transition: width 0.3s, background-color 0.3s;
    }

    .password-strength.level-off {
      width: 0%;
      background-color: transparent;
    }

    .password-strength.level-0 {
      width: 25%;
      background-color: red;
    }

    .password-strength.level-1 {
      width: 50%;
      background-color: orange;
    }
    .password-strength.level-2 {
      width: 75%;
      background-color: blue;
    }
    .password-strength.level-3 {
      width: 100%;
      background-color: green;
      border-bottom-right-radius: 5px;
    }

    .password-strength-label {
      position: absolute;
      right: 3px;
      bottom: 3px;
      color: black;
      font-size: 10px;
      text-align: right;
    }

    .input {
      padding: 10px;
      border-radius: 5px;
      border: 2px transparent solid;
      margin: -2px;
    }
    .input.invalid {
      border: 2px red solid;
    }

    .submit {
      padding: 10px 50px;
      border-radius: 5px;
      border: transparent;
      margin-top: 20px;
      cursor: pointer;
      transition: opacity 0.3s;
      min-width: 150px;
      text-align: center;
    }
    .submit:hover {
      opacity: 80%;
    }
  }
  .line {
    display: flex;
    gap: 10px;
    padding-bottom: 5px;
  }
  .message {
    padding-bottom: 25px;
    text-align: center;

    .bold {
      font-size: 24px;
    }
  }
  .error {
    margin-top: auto;
    color: red;
  }
  .busy {
    height: 25px;
    margin-top: 20px;
    color: var(--sl-gray05);
    padding: 5px;
  }
}
