.uploadimage {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
    align-items: center;
    height: calc(100% - 32px);

    .button {
      margin-right: auto;
      margin-left: auto;
    }

    .upload-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .drop-zone {
        width: 250px;
        height: 250px;
        margin-left: auto;
        background: var(--sl-stroke-transparent);
        margin-right: auto;
        border: 2px solid var(--sl-stroke);
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        color: var(--sl-white);
        justify-content: center;
        border-radius: 24px;
        font-family: Nunito;
        font-weight: 500;
        opacity: 0.8;
        transition: opacity 0.3s;
        text-align: center;
        
        span {
          margin: 20px;
        }
      }
      .drag-over {
        opacity: 1;
      }
    }

    .border {
      height: 100%;
      background: var(--sl-stroke);
      width: 1px;
    }

    .processing-area {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      gap: 20px;

      .header-bar {
        display: flex;
        width: 100%;
        align-items: center;

        .actions {
          margin-left: auto;
        }
      }

      .processing-list {
        display: flex;  
        flex-direction: column;
        flex-grow: 1;
        gap: 20px;
        overflow-y: scroll;
        margin-right: -10px;
        padding-right: 5px;

        .processing-list-empty {
          display: flex;
          color: var(--sl-white);
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
}
