.dashboard-header {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;

  .action-area {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 25px;
    justify-content: flex-start;
    user-select: none;

    .selection {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 120px;
      gap: 10px;
    }

    .message {
      font-weight: 300;
      font-size: 14px;
      color: var(--sl-white);
      opacity: 0.6;
    }

    .checkbox,
    .action {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      cursor: pointer;
    }
    .checkbox:hover,
    .action:hover {
      opacity: 1;
    }
    .checkbox:active,
    .action:active {
      opacity: 0.6;
    }
  }

  .tool-area {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
    margin-right: 7px;
    user-select: none;

    .results {
      font-weight: 300;
      font-size: 14px;
      color: var(--sl-white);
      opacity: 0.6;
      max-width: 90px;
      width: 90px;
      text-align: right;
    }

    .radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      width: 80px;
      height: 30px;
      border: 1px solid var(--sl-stroke);
      border-radius: 8px;
      justify-content: space-evenly;
      margin-left: 30px;

      .icon {
        width: 16px;
        height: 16px;
        opacity: 0.8;
        color: red;
        cursor: pointer;
      }
      .icon:hover {
        opacity: 1;
      }
      .icon:active {
        opacity: 0.7;
      }
    }
  }
}
