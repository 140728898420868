.library-list {
  width: 100%;
  height: 100%;

  .library-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    margin-left: 20px;

    .offset {
      flex: 1 1 0px;
    }

    .search-stats {
      flex: 0 0 100px;
      text-align: right;
      margin-right: 7px;
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 0.04em;
      opacity: 0.8;
    }

    .draft-message {
      flex: 1 1 0px;
      text-align: right;
      font-weight: bold;
    }
  }

  .content-wrapper {
    overflow-y: scroll;
    margin: 10px;
    margin-top: 20px;
    margin-left: 10px;
    height: calc(100% - 45px);
    overflow-x: hidden;
    width: calc(100% - 20px);
    padding-right: 15px;
    padding-left: 10px;

    .content {
      display: grid;
      grid-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(min(max(300px, 24%), 100%), 1fr));

      .item {
        max-width: 500px;
        background: var(--sl-card);
        box-shadow: var(--sl-sharp-shadow);
        border-radius: 16px;
        position: relative;
        display: flex;
        flex-direction: column;
        will-change: transform;
        cursor: pointer;

        .item-content {
          width: 100%;
          aspect-ratio: 16 / 9;
          position: relative;

          .marker {
            z-index: 1;
            position: absolute;
            width: 20%;
            top: 5%;
            left: 2%;
          }
          .image {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: fill;
            border-radius: 16px 16px 4px 4px;
          }
          .video {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 16px 16px 4px 4px;
          }
        }

        .metadata {
          padding: 16px 24px;
          height: 76px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex-grow: 1;
          position: relative;

          .name-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            width: 100%;

            .name {
              font-size: 16px;
              text-align: left;
              font-weight: 700;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: var(--sl-white);
              max-width: 100%;
            }
            .new {
              padding: 3px 4px;
              gap: 4px;
              width: 28px;
              background: var(--sl-yellow);
              border-radius: 16px;
              font-weight: 800;
              font-size: 8px;
              color: #000000;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .item:hover {
        background: var(--sl-card-hover);
      }
      // .item:active {
      //   background: var(--sl-card-active);
      // }
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 30px;
    top: 20%;
    justify-content: center;
    position: relative;

    .icon {
      width: 64px;
      height: 64px;
      opacity: 0.4;
      color: var(--sl-white);
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      color: var(--sl-white);
    }

    .info {
      font-weight: 400;
      font-size: 16px;
      color: var(--sl-off-white);
      text-align: center;
      line-height: 160%;
    }
  }
}
