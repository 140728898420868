:root {
  --sl-orange: #ff5c25;
  --sl-orange-50: #ff5c2580;
  --sl-yellow: #f78620;
  --sl-yellow-50: #f7862080;
  --sl-white: #1f1a30;
  --sl-white-75: #1f1a30c0;
  --sl-white-50: #1f1a3080;
  --sl-white-25: #1f1a3040;
  --sl-off-white: #120f1d;
  --sl-background: #fcfcfc;
  --sl-dark: #ededed;
  --sl-modal: #886192;
  --sl-separator: #886192;
  --sl-stroke: #58286414;
  --sl-stroke-transparent: #58286414;
  --sl-field: #4e4e52;
  --sl-action-red: #e85c3c;
  --sl-action-green: #0b7e00;

  --sl-soft-shadow: 4px 8px 24px #0c0b1129;
  --sl-sharp-shadow: 2px 2px 8px #07050729;
  --sl-list-shadow: 4px 4px 8px #00000014;
  --sl-modal-shadow: 0px 12px 24px #0c0b1129;

  --sl-brand: linear-gradient(270deg, #ffc542 0%, #ff5c25 100%);
  --sl-brand-hover: linear-gradient(270deg, #ffe042 0%, #ff6e25 100%);
  --sl-brand-active: linear-gradient(270deg, #f2b731 0%, #e8460f 100%);
  --sl-brand-disabled: linear-gradient(270deg, #71572c 0%, #713021 100%);

  --sl-card: #ffffff;
  --sl-card-hover: #f4f4f4;
  --sl-card-active: #c0c0c0;
  --sl-card-busy: #00000020;

  --sl-dropdown: #fcfcfc;
  --sl-dropdown-hover: #fcfcfc;
  --sl-dropdown-active: #fcfcfc;

  --sl-left-gradient: linear-gradient(90deg, #fcfcfcff 0%, #fcfcfc00 100%);
  --sl-right-gradient: linear-gradient(90deg, #fcfcfc00 0%, #fcfcfcff 100%);

  --sl-page-footer-height: 100px;

  --sl-google-ads-width: 300px;
  --sl-google-ads-height: 250px;
  --sl-google-ads-width-small: 150px;
  --sl-google-ads-height-small: 125px;

  --sl-opacity-default: 0.8;
  --sl-opacity-hover: 1;
  --sl-opacity-active: 0.6;
}

[data-theme="dark"] {
  --sl-orange: #ff5c25;
  --sl-yellow: #ffc542;
  --sl-white: #ffffff;
  --sl-white-75: #ffffffc0;
  --sl-white-50: #ffffff80;
  --sl-white-25: #ffffff40;
  --sl-off-white: #eaeaea;
  --sl-background: #120f1d;
  --sl-dark: #1f1a30;
  --sl-modal: #302a46;
  --sl-separator: #886192;
  --sl-stroke: #582864;
  --sl-stroke-transparent: #58286488;
  --sl-field: #5b4661;

  --sl-soft-shadow: 4px 8px 24px #0c0b1166;
  --sl-sharp-shadow: 2px 2px 8px #07050729;
  --sl-list-shadow: 4px 4px 8px #00000014;
  --sl-modal-shadow: 0px 12px 24px #0c0b1199;

  --sl-card: #1f1a30;
  --sl-card-hover: #1c233d;
  --sl-card-active: #101424;
  --sl-card-busy: #00000020;

  --sl-dropdown: #302a46;
  --sl-dropdown-hover: #383152;
  --sl-dropdown-active: #2a243d;

  --sl-left-gradient: linear-gradient(90deg, #120f1dff 0%, #120f1d00 100%);
  --sl-right-gradient: linear-gradient(90deg, #120f1d00 0%, #120f1dff 100%);
}

body,
html {
  touch-action: manipulation;
  width: 100%;
  height: 100%;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  background-color: var(--sl-background);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #6960844a;
}
::-webkit-resizer {
  background-color: #6960844a;
}

* {
  outline: 0px;
  // it seems that the scrollbar-width works only when I assign it like this
  // scrollbar-width: thin;
}
img {
  -webkit-user-drag: none;
  user-select: none;
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  color: var(--sl-white);
  background-color: var(--sl-background);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .window-too-small {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    z-index: 2;
    background: #000000d0;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;

    .icon {
      fill: var(--sl-action-red);
      width: 80px;
      height: 80px;
    }

    .main-text {
      max-width: 400px;
      color: #eaeaea;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }

    .sub-text {
      max-width: 400px;
      color: #eaeaea;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      font-size: 12px;
      opacity: 0.8;
    }
    .button-container {
      display: flex;
      gap: 20px;

      .button {
        width: 120px;
        height: 48px;
        background: var(--sl-brand);
        color: #ffffff;
        border-radius: 56px;
        font-family: Nunito;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.04em;
        border-radius: 56px;
        cursor: pointer;
        border: 0px;
      }
      .button:hover {
        background: var(--sl-brand-hover);
      }
      .button:active {
        background: var(--sl-brand-active);
      }
    }
  }
  .window-too-small.active {
    pointer-events: all;
    opacity: 1;
  }
}

.toplevel {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &.the-best-hidden {
    .app {
      width: 100%;
    }
  }
  .the-best-hidden {
    display: none;
  }

  &.the-best-vertical {
    flex-direction: row;
    .app {
      width: calc(100% - var(--sl-google-ads-width));
    }
  }
  &.the-best-vertical.small {
    .app {
      width: calc(100% - var(--sl-google-ads-width-small));
    }
  }
  .the-best-vertical {
    width: calc(var(--sl-google-ads-width) + 10px);
    height: 100%;
    border-left: 1px solid var(--sl-stroke);

    &.small {
      width: calc(var(--sl-google-ads-width-small) + 10px);
    }
  }

  &.the-best-horizontal {
    flex-direction: column;
    .app {
      height: calc(100% - var(--sl-google-ads-height));
    }
  }
  &.the-best-horizontal.small {
    .app {
      height: calc(100% - var(--sl-google-ads-height-small));
    }
  }
  .the-best-horizontal {
    width: 100%;
    height: calc(var(--sl-google-ads-height) + 10px);
    border-top: 1px solid var(--sl-stroke);

    &.small {
      height: calc(var(--sl-google-ads-height-small) + 10px);
    }
  }
}

.busy {
  color: var(--sl-off-white);
}

@keyframes rotate-z {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: rotate-z;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

button {
  user-select: none;
}

.full-screen-panel {
  display: flex;
  flex: 1 1 0px;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

// customize the tool top
.__react_component_tooltip.show {
  opacity: 1 !important;
}

// customize toastify
.Toastify__toast {
  cursor: default;
}
.Toastify__toast-container {
  width: 90%;
  max-width: 800px;
  top: 24px;
  --toastify-toast-min-height: 56px;
  --toastify-toast-max-height: 116px;
  --toastify-font-family: Nunito;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  background: var(--sl-action-green);
}
.Toastify__toast-theme--light.Toastify__toast--error {
  background: var(--sl-action-red);
}
.Toastify__toast-icon {
  margin-inline-end: 22px;
  margin-left: 14px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
  color: #fff;
}
.Toastify__toast-body {
  color: #fff;
}
.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
  color: #fff;
  opacity: 0.8;
  top: 13px;
  position: relative;
  right: 19px;
  transition: none;
  cursor: pointer;
}
.Toastify__close-button--light:hover {
  opacity: 1;
}
.Toastify__close-button--light:active {
  opacity: 0.6;
}
