.home-categories {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
  --title-height: 0px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--sl-white);
  }

  .carousel {
    position: relative;
    width: 100%;
    height: 200px;
    .items {
      position: absolute;
      width: 100%;
      height: 100%;

      .item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        min-width: 150px;
        cursor: pointer;
  
        .icon {
          border: 2px dashed rgba(75, 82, 109, 0.7);
          padding: 10px;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 110px;
          opacity: 0.9;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: var(--sl-white);
          opacity: 0.8;
        }
      }
      .item:hover {
        .icon {
          border: 2px dashed rgba(75, 82, 109, 1);
          opacity: 1;
        }
        .label {
          opacity: 1;
        }
      }
      .item:active {
        .icon {
          border: 2px dashed rgba(75, 82, 109, 0.6);
          opacity: 0.8;
        }
        .label {
          opacity: 0.8;
        }
      }
    }
  }
}
