.home {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 15px;

  --title-height: 55px;

  .title {
    color: var(--sl-white);
    font-size: 65px;
    display: block;
    margin-block: unset;
    font-weight: unset;
  }

  .tagline {
    color: var(--sl-white);
    font-size: 30px;
    padding-bottom: 20px;
    display: block;
    margin-block: unset;
    font-weight: unset;
  }
  .tagline-spacer {
    flex: 0 0 20px;
  }

  .shadow-left {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: calc(100% - var(--title-height));
    width: 130px;
    background: var(--sl-left-gradient);
    pointer-events: none;
  }
  .shadow-left.small {
    width: 35px;
  }

  .shadow-right {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: calc(100% - var(--title-height));
    width: 130px;
    background: var(--sl-right-gradient);
    pointer-events: none;
  }
  .shadow-right.small {
    width: 35px;
  }

  .arrow-button {
    width: 44px;
    height: 44px;
    border: 1px solid var(--sl-white-50);
    border-radius: 30px;
    background: var(--sl-dark);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 0.8;
    box-shadow: var(--sl-sharp-shadow);
    cursor: pointer;
  }
  .arrow-button:hover {
    opacity: 1;
  }
  .arrow-button:active {
    opacity: 0.7;
  }

  .arrow-button.left {
    left: 16px;
  }
  .arrow-button.right {
    right: 16px;
  }
}
