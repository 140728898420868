.error {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    color: red;
    width: 200px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: var(--sl-white);
    margin-top: 48px;
  }

  .message {
    font-weight: 400;
    font-size: 16px;
    color: var(--sl-off-white);
    margin-top: 12px;
  }
}
