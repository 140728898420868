.header-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;

  .button {
    font-family: Nunito;
    font-size: 14px;
    width: 120px;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    opacity: 0.6;
    color: var(--sl-white);
    border: 1px solid #886192;
    border-radius: 32px;
    gap: 8px;
    cursor: pointer;
  }
  .button:hover {
    opacity: 0.8;
  }
  .button:active {
    opacity: 0.5;
  }

  .menu-background {
    box-shadow: var(--sl-modal-shadow);
    border-radius: 8px;
    background: var(--sl-dropdown);
    width: 120px;
    min-width: 0px;
    padding: 0px;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 96px;
    height: 16px;
    border-bottom: 1px solid var(--sl-stroke);
    color: var(--sl-white);
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  .menu-item:hover {
    background: var(--sl-dropdown-hover);
  }
  .menu-item:active {
    background: var(--sl-dropdown-active);
  }
  .menu-item:nth-child(2) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .menu-item:last-child {
    border-bottom: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.header-menu:hover {
  .back {
    opacity: 0.6;
  }
  .label {
    opacity: 0.8;
  }
  .icon {
    opacity: 0.8;
  }
}
.header-menu:active {
  .back {
    opacity: 0.3;
  }
  .label {
    opacity: 0.5;
  }
  .icon {
    opacity: 0.5;
  }
}
