.folder-menu {
  button {
    background: transparent;
    border: none;
  }
  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 10px;
    cursor: pointer;

    .back {
      width: 100%;
      height: 100%;
      background: var(--sl-stroke);
      border-radius: 32px;
      opacity: 0.4;
      position: absolute;
    }
    .icon {
      color: var(--sl-white);
      opacity: 0.8;
    }

    .message {
      font-family: Nunito;
      font-weight: 600;
      font-size: 14px;
      color: var(--sl-orange);
      opacity: 0.9;
    }
  }
  .label {
    font-family: Nunito;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: var(--sl-white);
    opacity: 0.8;
    cursor: default;
    padding-top: 12px;
    white-space: nowrap;
  }

  .menu-background {
    box-shadow: var(--sl-modal-shadow);
    border-radius: 8px;
    background: var(--sl-dropdown);
    width: 172px;
    padding: 0px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 148px;
    height: 16px;
    border-bottom: 1px solid var(--sl-stroke);
    color: var(--sl-white);
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    .name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name.no-folder {
      color: var(--sl-orange);
    }
    .name.create-folder {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .menu-item:hover {
    background: var(--sl-dropdown-hover);
  }
  .menu-item:active {
    background: var(--sl-dropdown-active);
  }
  .menu-item:nth-child(2) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .menu-item:last-child {
    border-bottom: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.folder-menu:hover {
  .back {
    opacity: 0.6;
  }
  .label {
    opacity: 0.8;
  }
  .icon {
    opacity: 1;
  }
  .message {
    opacity: 1;
  }
}
.folder-menu:active {
  .back {
    opacity: 0.3;
  }
  .label {
    opacity: 0.5;
  }
  .icon {
    opacity: 0.6;
  }
  .message {
    opacity: 0.6;
  }
}
