.home-featured {
  width: 100%;
  display: grid;
  margin-top: 80px;
  position: relative;
  user-select: none;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--sl-white);
    height: var(--title-height);
    margin-block: unset;
  }

  .carousel {
    padding-bottom: 10px;
  }
}
