.good-stuff {
  .uno-good-stuff {
    color: var(--sl-white);
    overflow: hidden;

    .loading {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }

    .uno-ad {
      width: 300px;
      height: 250px;
      position: relative;

      &.link {
        cursor: pointer;
      }

      .image {
        position: absolute;
        object-fit: cover;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .title {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 16px;
        background: var(--sl-white-75);
        padding: 2px 20px;
        border-radius: 5px;
        color: var(--sl-dark);
      }
      .message {
        position: absolute;
        bottom: 5px;
        left: 5px;
        font-size: 12px;
        background: var(--sl-white-75);
        padding: 2px 20px;
        border-radius: 5px;
        color: var(--sl-dark);
      }
    }
  }

  .filler {
    flex: 1;
  }

  .good-stuff-slot {
    ins.adsbygoogle,
    .google-ads-placeholder {
      display: inline-block;
      width: 300px;
      height: 250px;
      position: absolute;
      z-index: 1;
      border-radius: 10px;
      overflow: hidden;
    }

    // // // this the google ad-status is unfilled then hide the ad
    ins.adsbygoogle[data-adsbygoogle-status="done"][data-ad-status="unfilled"] {
      z-index: 0;
    }

    // // // if the google ad-status doesn't exist then hide the ad
    ins.adsbygoogle[data-adsbygoogle-status="done"]:not([data-ad-status]) {
      z-index: 0;
    }

    // // if the google ad is shown then hide the uno ad
    :has(ins.adsbygoogle[data-ad-status="filled"]) ~ .uno-ad {
      display: none;
    }
  }
}

.good-stuff.hidden {
  display: none;
}

.good-stuff.vertical {
  width: calc(100% - 10px);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
  justify-content: flex-start;
  align-items: flex-start;

  &.small {
    transform: scale(0.5);
    transform-origin: top left;
    height: calc(200% - 20px);
    width: 200%;
  }

  .good-stuff-title {
    font-size: 14px;
    font-weight: 500;
    color: var(--sl-white);
    text-align: center;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 2px;
  }

  .uno-good-stuff {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    margin-top: 5px;
    flex: 1 1 1px;
    flex-wrap: wrap;
  }

  .remove-good-stuff {
    display: flex;
    width: 65%;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    color: white;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 15px auto;
  }
}

.good-stuff.horizontal {
  width: calc(100% - 10px);
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0px 5px;
  justify-content: flex-start;
  align-items: flex-start;

  &.small {
    transform: scale(0.5);
    transform-origin: top left;
    width: calc(200% - 20px);
    height: 200%;
  }

  .good-stuff-title {
    display: none;
  }

  .uno-good-stuff {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    margin: 5px 0px;
    flex: 1 1 1px;
    flex-wrap: wrap;
  }

  .remove-good-stuff {
    display: flex;
    width: 150px;
    height: calc(100% - 30px);
    align-items: center;
    justify-content: space-evenly;
    color: white;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.good-stuff-link {
  color: var(--sl-yellow);
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.6;
  }
}