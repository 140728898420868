.library-browser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .columns {
    display: flex;
    flex-direction: row;
    flex: 1 1 1px;
    height: 1px;

    .left-column {
      width: 190px;
      height: calc(100% + 66px);
      position: relative;
      top: -66px;
      padding-right: 5px;
      border-right: 1px solid var(--sl-dark);
    }
    .right-column {
      width: calc(100% - 196px);
      position: relative;
    }
  }
}
