.sidebar {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0px 0px;
  gap: 24px;
  height: calc(100% - 40px);
  border-right: 1px solid var(--sl-stroke);
  flex: 0 0 234px;

  .logo {
    position: relative;
    height: 35px;
    left: 24px;
    top: 2px;
    border-radius: 0px;
    opacity: 1;
    transition: opacity 0.5s;
    &.dark {
      opacity: 0.5;
    }
  }

  .logo-uno-video {
    pointer-events: none;
    position: absolute;
    width: 180px;
    left: 24px;
    top: 8px;
  }


  .new-overlay {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
    margin: 30px 24px 0px 24px;
    width: 170px;
    height: 48px;
    color: #ffffff;
    background: var(--sl-brand);
    border-radius: 56px;
    cursor: pointer;
  }
  .new-overlay:hover {
    background: var(--sl-brand-hover);
  }
  .new-overlay:active {
    background: var(--sl-brand-active);
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    margin: 16px 14px 16px 23px;
    overflow-y: scroll;
    padding-right: 10px;

    .entry {
      height: 46px;
      width: 180px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      flex-shrink: 0;

      .title {
        position: relative;
        top: 1px;
        opacity: var(--sl-opacity-default);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

      .icon {
        fill: var(--sl-white);
        opacity: var(--sl-opacity-default);
        flex-shrink: 0;
        display: flex;
      }
      .icon.small {
        width: 16px;
      }

      .add,
      .show {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: var(--sl-opacity-default);
        cursor: pointer;
      }
      .add,
      .busy {
        margin-left: auto;
      }
      .add:hover,
      .show:hover {
        opacity: var(--sl-opacity-hover);
      }
      .add:active,
      .show:active {
        opacity: var(--sl-opacity-active);
      }

      .input {
        padding: 0px 5px;
        height: 30px;
        width: 100%;
        border: 1px solid #886192;
        border-radius: 8px;
        background: transparent;
        color: var(--sl-white);
        font-size: 16px;
        font-weight: 400;
        font-family: Nunito;
      }
    }

    .entry:hover:not(.folders):not(.toplevel) {
      .title,
      .icon {
        opacity: var(--sl-opacity-hover);
      }
      .dropdownmenu {
        button {
          opacity: 1;
        }
      }
    }
    .entry:active:not(.folders):not(.toplevel) {
      .title,
      .icon {
        opacity: var(--sl-opacity-active);
      }
    }

    .folders .title:hover,
    .toplevel .title:hover {
      opacity: var(--sl-opacity-hover);
      ~ .icon {
        opacity: var(--sl-opacity-hover);
      }
    }

    .folders .title:active,
    .toplevel .title:active {
      opacity: var(--sl-opacity-active);
    }

    .entry.sub {
      width: 156px;
      padding-left: 24px;
      height: 36px;
    }

    .entry.sub.nomouse {
      pointer-events: none;
    }

    .entry.toplevel {
      width: 170px;
      gap: 10px;

      .show {
        margin-left: auto;
      }
    }

    .entry.folders {
      margin-top: 5px;
      width: 146px;
      gap: 10px;
    }

    .entry.selected {
      .title {
        opacity: 1;
        color: var(--sl-yellow);
      }
      .icon {
        opacity: 1;
      }
    }

    .divider {
      border-top: 1px dashed var(--sl-separator);
      height: 5px;
      width: 170px;
      margin-top: 5px;
      opacity: var(--sl-opacity-default);
      flex-shrink: 0;
    }
  }

  .dropdownmenu {
    display: flex;
    margin-left: auto;
    cursor: pointer;

    button {
      opacity: 0;
      background: transparent;
      border: 0px;
      padding: 0px;
      position: relative;
      top: 2px;
      transition: opacity 0.3s;
      cursor: pointer;
    }
    .icon {
      width: 13px;
      height: 13px;
      color: var(--sl-white);
      opacity: 0.6;
    }
    .label {
      font-family: Nunito;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: var(--sl-white);
      opacity: 0.6;
      cursor: default;
      padding-top: 12px;
      white-space: nowrap;
    }

    .menu-background {
      box-shadow: var(--sl-modal-shadow);
      border-radius: 8px;
      background: var(--sl-dropdown);
      width: 100px;
      min-width: 100px;
      padding: 0px;
      overflow: hidden;
    }

    .menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 8px;
      width: 76px;
      height: 16px;
      border-bottom: 1px solid var(--sl-stroke);
      color: var(--sl-white);
      text-decoration: none;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
    }
    .menu-item:hover {
      background: var(--sl-dropdown-hover);
    }
    .menu-item:active {
      background: var(--sl-dropdown-active);
    }
    .menu-item:nth-child(2) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .menu-item:last-child {
      border-bottom: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .dropdownmenu:hover {
    .back {
      opacity: 0.6;
    }
    .label {
      opacity: 0.8;
    }
    .icon {
      opacity: 0.8;
    }
  }
  .dropdownmenu:active {
    .back {
      opacity: 0.3;
    }
    .label {
      opacity: 0.5;
    }
    .icon {
      opacity: 0.5;
    }
  }
}

.sidebar.small {
  flex: 0 0 64px;

  .logo {
    height: 30px;
    margin-top: 3px;
    margin-bottom: 3px;
    top: 2px;
    left: 15px;
  }

  .new-overlay {
    width: 48px;
    margin: 30px 7px 0px 7px;
  }

  .menu {
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 24px;

    .entry {
      width: 25px;
      position: relative;
      left: -24px;
      padding-left: 15px;
      margin-right: -11px;
      border-left: 3px solid transparent;
      font-size: 10px;
    }
    .entry.highlight {
      border-left: 3px solid #ffc54266;
    }

    .divider {
      width: 32px;
      position: relative;
      left: -12px;
    }

    .entry.selected {
      opacity: 1;
      background: linear-gradient(90deg, #ffc54228 0%, #ffc54200 100%);
      border-left: 3px solid var(--sl-yellow);
      .icon {
        fill: var(--sl-yellow);
      }
    }
  }
}
