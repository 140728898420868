.home-welcome {

  // define a variable for the image size
  --sl-image-size: 360px;

  display: flex;
  height: var(--sl-image-size);
  width: 100%;
  gap: 24px;


  .ticker-video {
    width: 100%;
    position: absolute;
    bottom: 45px;
  }

  .text-column {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    color: #ffffff;

    .title {
      font-weight: 700;
      font-size: 32px;
      margin-left: 32px;
      width: 350px;
    }

    .message {
      font-weight: 400;
      font-size: 16px;
      margin-left: 32px;
      margin-top: 10px;
      width: 300px;
    }

    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 11px 24px;
      width: 144px;
      height: 26px;
      border: 1px solid #ffffff;
      border-radius: 56px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.04em;
      opacity: 0.8;
      margin: 32px;
      cursor: pointer;
    }
    .button:hover {
      opacity: 1;
    }
    .button:active {
      opacity: 0.7;
    }

    .offset {
      height: 32px;
    }
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--sl-image-size);
    height: var(--sl-image-size);
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 40px;

    .text-column {
      position: absolute;
      color: #000000;
      pointer-events: none;
    }

    .title {
      color: #120f1d;
    }

    .message {
      color: #606165;
    }

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .right {
    position: relative;
    overflow: hidden;
    height: var(--sl-image-size);
    flex: 1 1;
    border-radius: 40px;

    .content-loop {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s;

      .promo-image {
        transition: opacity 0.5s;
        overflow: hidden;

        img {
          width: 485px;
          height: 272px;
          border-radius: 15px;
          object-fit: contain;
        }
      }
    }
  }

  .right-image-only {
    overflow: hidden;
    height: var(--sl-image-size);
    flex: 1 1;
    position: relative;

    .content-loop {
      display: flex;
      align-items: center;
      height: var(--sl-image-size);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s;
      position: absolute;

      .promo-image {
        transition: opacity 0.5s;
        overflow: hidden;
        height: 100%;
        border-radius: 40px;

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .promo-image.link {
    cursor: pointer;
  }

  .content-loop.active {
    pointer-events: all;
    opacity: 1;
  }

  .right.no-images {
    .content-loop {
      .image {
        opacity: 0;
      }
    }
  }
}
