.account {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: scroll;

  &-header {
    font-size: 24px;
    color: var(--sl-white);
    padding-bottom: 50px;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--sl-white);
    margin-left: 24px;

    .title {
      width: 250px;
      font-weight: 700;
      font-size: 16px;
      opacity: 0.8;
    }

    .input {
      padding: 0px 24px;
      width: 374px;
      height: 40px;
      border: 1px solid #886192;
      border-radius: 8px;
      background: transparent;
      color: var(--sl-white);
      font-size: 16px;
      font-weight: 400;
      font-family: Nunito;
    }
    .input:disabled {
      opacity: 0.8;
    }
    .input.off {
      opacity: 0.8;
      border: 1px solid transparent;
    }

    .autosaving {
      display: flex;
      align-items: center;
      gap: 5px;
      padding-left: 12px;
      span {
        font-weight: 400;
        font-size: 12px;
      }

      .busy {
        width: 15px;
        height: 15px;
      }
    }

    .toggle {
      display: flex;
      cursor: pointer;
    }

    .text {
      padding-left: 9px;
      font-size: 16px;
      font-weight: 400;
      font-family: Nunito;
      opacity: 0.8;
      cursor: default;

      .busy {
        width: 15px;
        height: 15px;
      }
    }

    .selection {
      display: flex;
      gap: 30px;

      .button-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        opacity: 0.8;
        cursor: pointer;

        .button {
          width: 177px;
          height: 103px;
          border: 2px solid rgba(255, 255, 255, 0.2);
          border-radius: 6px;
          padding: 5px;
          cursor: pointer;
        }
        .button.light {
          background: var(--sl-dark);
        }
        .button.dark {
          background: #120f1d;
        }

        .label {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .button-group:hover {
        opacity: 1;
      }
      .button-group.active {
        opacity: 0.6;
      }
      .button-group.selected {
        .button {
          border: 2px solid var(--sl-orange);
        }
      }
    }
    &.align-top {
      align-items: flex-start;
    }
    &.warning {
      color: var(--sl-action-red);
      .title,
      .text {
        opacity: 1;
      }
    }
  }

  .button-line {
    display: flex;
    border-top: 1px dashed var(--sl-stroke);
    width: max-content;
    gap: 16px;
    padding-left: 230px;
    margin-left: 24px;

    .button {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 46px;
      border: 1px solid var(--sl-white);
      color: var(--sl-white);
      border-radius: 56px;
      opacity: 0.8;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.04em;
      margin-top: 40px;
    }
    .button.delete {
      border: 1px solid var(--sl-action-red);
      color: var(--sl-action-red);
    }
    .button:hover {
      opacity: 1;
    }
    .button:active {
      opacity: 0.6;
    }
    .button.disabled,
    .button.disabled:hover,
    .button.disabled:active {
      cursor: default;
      opacity: 0.5;
    }
  }

  .info {
    color: red;
    padding-top: 10px;
    width: 80%;
    text-align: center;
  }
}
