.dashboard-myoverlays {
  display: flex;
  width: 100%;
  height: calc(100% - 80px);
  flex-direction: column;

  .list-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: var(--sl-white);
      height: 20px;
      margin-block: unset;
    }
  }

  .content {
    padding-top: 20px;
    width: 100%;
    height: calc(100% - 80px);

    .fetch-busy {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
