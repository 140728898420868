.footer {
  height: calc(var(--sl-page-footer-height) - 1px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 42px;
  border-top: 1px solid var(--sl-stroke);

  .logo {
    width: 150px;
  }

  .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: var(--sl-white);
    text-decoration: none;
    cursor: pointer;
    opacity: 0.8;
  }
  .nav-item:hover {
    opacity: 0.9;
  }
  .nav-item:active {
    opacity: 0.5;
  }

  .bottom-nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex: 1 1 300px;
    justify-content: space-evenly;
    max-width: 800px;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100%;
    min-width: 180px;

    .buy-me-a-coffee {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 25px;
      height: 48px;

      img {
        height: 25px;
        width: 18px;
        margin-right: 7px;
      }
    }
  }

  .copyright {
    font-weight: 500;
    font-size: 12px;
    color: var(--sl-white);
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    flex-shrink: 0;

    .socials {
      display: flex;
      gap: 10px;
      margin-top: 5px;
      margin-bottom: 5px;

      img {
        height: 30px;
        width: 30px;
        cursor: pointer;
      }
    }

    .rights {
      opacity: 0.8;
    }
    .legal {
      display: flex;
      .separator {
        padding: 0px 10px;
      }
      .nav-item {
        font-size: 12px;
      }
    }
  }
}
