.modal-dialog.librarydetails {
  display: flex;
  width: calc(100% - 250px);
  height: 100% !important;
  max-width: 1000px;
  //  min-width: 500px;
  min-height: fit-content;
  max-height: 550px !important;
}

.librarydetails {
  .arrow {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: 1px solid #ffffff33;
    border-radius: 24px;
    color: var(--sl-white);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 32px);
    // background: var(--sl-stroke-transparent);
    background: var(--sl-background);
    opacity: 0.8;
  }
  .arrow.left {
    left: -65px;
  }
  .arrow.right {
    right: -65px;
  }

  .arrow:hover {
    opacity: 1;
  }
  .arrow:active {
    opacity: 0.6;
  }
  .arrow.hidden {
    display: none;
  }

  .content {
    flex: 1 1 1px;
    border-radius: 10px;
    display: flex;
    position: relative;
    gap: 0px !important;

    .new {
      padding: 4px 7px 2px 7px;
      background: var(--sl-yellow);
      border-radius: 16px;
      font-weight: 800;
      font-size: 10px;
      color: #000000;
    }

    .spacer {
      height: 25px;
    }

    .horizontal {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex: 1 1 1px;
      gap: 32px;

      .left {
        flex: 2 0 200px;
      }
      .right {
        display: flex;
        flex-direction: column;
        flex: 1 0 100px;
      }

      .tags-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .tag-label {
          display: flex;
          align-items: center;
          height: 24px;
          font-size: 12px;
          font-weight: 400;
          color: var(--sl-white-75);
        }
      }

      .variants {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 15px;

        .variant-label {
          display: flex;
          align-items: center;
          height: 38px;
          font-size: 12px;
          font-weight: 400;
          color: var(--sl-white-75);
        }
        .variant-tags {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          max-height: 88px;
          overflow: hidden;

          .variant-tag {
            display: flex;
            gap: 5px;
            font-weight: 400;
            font-size: 14px;
            color: var(--sl-white);
            padding: 10px 14px;
            background: var(--sl-stroke);
            border-radius: 24px;
            cursor: default;

            &-label {
              max-width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }

      .visual {
        position: relative;
        width: 100%;
        aspect-ratio: 16 / 9;
        margin-bottom: 15px;

        .video {
          width: 100%;
          aspect-ratio: 16 / 9;
          min-width: 100%;
        }
        .image {
          width: 100%;
          aspect-ratio: 16 / 9;
          min-width: 100%;
          &.overlay {
            position: absolute;
            top: 0px;
            left: 0px;
          }
        }
        .videoLoading {
          position: absolute;
          display: flex;
          top: 0px;
          width: 100%;
          aspect-ratio: 16 / 9;
          justify-content: center;
          align-items: center;
          color: var(--sl-white);
        }
      }

      .ql-editor {
        flex: 1 1 1px;
        min-height: 60px;
        width: 100%;
        padding: 0px;
        font-size: 14px;
        font-weight: 400;
        color: var(--sl-off-white);
        overflow-x: hidden;
        overflow-y: scroll;
        margin-block: 0px;
        word-break: break-word;
      }
      .ql-editor::-webkit-scrollbar-thumb {
        background-color: var(--sl-modal);
      }

      .description {
        display: flex;
        color: var(--sl-white);
        flex-direction: column;
        flex: 1 1 1px;

        &-header {
          color: var(--sl-white);
          font-size: 16px;
          font-weight: 700;
        }
        &-text {
          color: var(--sl-white);
          font-size: 14px;
          font-weight: 400;
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 20px;
        background-color: var(--sl-card);
        align-items: flex-end;

        .button,
        .button-brand {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.library-detail.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 750px) {
  .modal-dialog.librarydetails {
    max-height: 850px !important;

    .horizontal {
      flex-direction: column;
      .left {
        flex: 1 1 1px;
      }
    }
  }
}
