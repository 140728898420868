.dashboard-myimages {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);

  .drop-zone {
    padding-top: 20px;
    width: 100%;
    height: calc(100% - 80px);
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .drop-zone-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s;

      .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 350px;
        height: 350px;
        background-color: var(--sl-modal);
        border-radius: 16px;
        gap: 20px;

        .icon {
          width: 128px;
          height: 128px;
          color: var(--sl-white);
        }

        .title {
          font-weight: 700;
          font-size: 24px;
          color: var(--sl-white);
        }
      }
    }
    .drop-zone-overlay.visible {
      opacity: 1;
    }
  }

  .list-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: var(--sl-white);
      height: 20px;
      margin-block: unset;
    }
  }

  .content {
    width: 100%;
    height: 100%;

    .fetch-busy {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
